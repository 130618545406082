<template>
    <div class="blogContainer">
        <div class="navContainer"></div>
        <h1>Latest posts</h1>
        <img class="logoBlack" src="@/assets/cl-logo-blog.png" @click="$emit('changeRoute',
        {name: 'home'})">
        <div class="postsContainer">
            <div v-for="(post, index) in posts" :key="index" class="post">
                <div class="postImg" :style="{backgroundImage: 'url('+post.img+')'}" @click="goToPost(index)">
                    <div class="postShadow"></div>
                </div>
                <div class="postText">
                    <h1>{{post.title}}</h1>
                    <h2>{{post.subtitle}}</h2>
                    <div class="btn animated" @click="goToPost(index)">
                        <div></div>
                        <span>Read More</span>
                    </div>
                </div>
            </div>
        </div>
        <footerComp/>
    </div>
</template>

<style lang="sass">
$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

.blogContainer
    #footer
        background-color: black !important
    .navContainer
        height: 120px
        width: 100vw
        position: fixed
        z-index: 150
        left: 0
        top: 0
        background: #fff
        @media (max-width: 1100px)
            height: 95px
    margin: 0
    width: 100vw
    height: auto
    background-color: #fff
    position: absolute
    left: 0
    top: 0
    display: flex
    flex-direction: column
    align-items: center
    .postsContainer
        margin: 150px 0 200px 0
        @media (max-width: 1100px)
            margin-bottom: 100px
        .post
            *
                margin: 0
                padding: 0
                text-align: left
                display: block
            transform: translateY(100px)
            opacity: 0
            animation: fade-up-blog 1s 1.5s ease-in-out forwards
            .postImg
                width: 45%
                height: 500px
                background-size: cover
                @media (max-width: 1100px)
                    width: 90%
                    margin: auto
                    margin-bottom: 30px
                    height: 90vw
                .postShadow
                    position: relative
                    transition: all .5s ease-in-out
                    z-index: -1
                    width: 100%
                    height: 100%
                    background: $color5
                &:hover
                    .postShadow
                        transform: translate(30px, 30px)
            @media (max-width: 1100px)
                margin: 0 0 100px 0
            display: flex
            align-items: center
            width: 100vw
            @media (max-width: 1100px)
                flex-direction: column
            box-sizing: border-box
            height: auto
            @media (min-width: 1100px)
                &:nth-child(2n)
                    flex-direction: row-reverse
                    justify-content: space-between
            .postText
                padding: 100px
                @media (max-width: 1100px)
                    padding: 0
                h1, h2, p
                    color: black
                    @media (max-width: 1100px)
                        text-align: center
                h1
                    font-size: 60px
                    @media (max-width: 1100px)
                        font-size: 30px
                h2
                    font-size: 30px
                    margin: 20px 0
                    @media (max-width: 1100px)
                        font-size: 20px
                        margin: 10px 0 20px 0
                small
                    margin-bottom: 7px
                    font-style: italic
                .btn
                    @media (max-width: 1100px)
                        margin: auto
                    margin-top: 15px
                    width: 100px
                    box-shadow: inset 0px 0px 0px 2px black
                    div
                        background-color: black
                    span
                        color: black
                        text-align: center
                    &:hover
                        span
                            color: #fff


@keyframes fade-up-blog 
    0%
        transform: translateY(100px)
        opacity: 0
    100%
        transform: translateY(0)
        opacity: 1
</style>

<script>
export default {
    //VueMeta
    metaInfo: {
        title: 'Blog',
        meta: [
            {
                //vmid: 'description',
                name: 'description',
                content:
                    'Our Blog showcasing our latest Apps, Services and Shopify related news.'
            }
        ]
    },
    data() {
        return {
            posts: [],
            CMS: null
        }
    },

    beforeCreate() {
        // CMS DEMONSTRATION
        this.$prismic.client.getSingle('blog')
        .then(doc => {
            this.CMS = doc.data.body
            this.CMS.forEach(post => {
                // eslint-disable-next-line no-console
                console.log(post.primary.post_content[0].text)
                this.posts.push({
                    img: post.primary.post_image.url || require('./../assets/blogSamplePic.png'),
                    date: post.primary.post_date[0].text.replace(/\s+/g, ' '),
                    title: post.primary.post_title[0].text.replace(/\s+/g, ' '),
                    subtitle: post.primary.post_subtitle[0].text.replace(/\s+/g, ' '),
                    content: post.primary.post_content[0].text.replace(/\s+/g, ' '),
                })
            })
        })
        //
    },

    methods: {
        goToPost(index) {
            this.$store.state.currentPost = this.posts[index]
            this.$emit('changeRoute', {name: 'post'})
        }
    },

    mounted() {
        document.body.style.height = "auto"
        window.scrollTo(0, 0)

        setTimeout(() => {
        this.playAnimText = true
        }, 1200)
    },

    destroyed() {
        document.body.style.height = "1000vh"
    }
}
</script>